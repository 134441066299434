import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Button, Toolbar, Hidden } from "@material-ui/core";
import InputIcon from "@material-ui/icons/Input";
import { logout } from "../../logout";
import { connect, useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
  header: {
    textDecoration: "none",
    color: "white",
    fontSize: "26px",
    letterSpacing: "1px",
  },
}));

function TopBar({ className, ...rest }) {
  const classes = useStyles();
  const currentType = rest.currentType;
  const dispatch = useDispatch();

  const handleRouteRedirect = () => {
    if (currentType === "DEVICES") {
      return "/main/devices/Information";
    } else {
      return "/main/assemblies/Information";
    }
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <AppBar className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <RouterLink to={handleRouteRedirect}>
          <h1 className={classes.header}>Manufacturing Tool Dashboard</h1>
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <Button
            className={classes.logoutButton}
            color="inherit"
            onClick={handleLogout}
          >
            <InputIcon className={classes.logoutIcon} />
            Sign out
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    currentType: state.currentTypeState.currentType,
  };
};

export default connect(mapStateToProps, null)(TopBar);

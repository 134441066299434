import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  assemblyPagination: { skip: 0, limit: 20 },
  devicesPagination: { skip: 0, limit: 20 },
  firmwarePagination: { skip: 0, limit: 20 },
};

const paginationSlice = createSlice({
  name: "pagination",
  initialState: initialState,
  reducers: {
    setAssemblyPagination: (state, action) => {
      state.assemblyPagination = action.payload;
    },
    setDevicePagination: (state, action) => {
      state.devicesPagination = action.payload;
    },
    setFirmwarePagination: (state, action) => {
      state.firmwarePagination = action.payload;
    },
    clearAssemblyPagination: (state, action) => {
      state.assemblyPagination = { skip: 0, limit: 20 };
    },
    clearDevicesPagination: (state, action) => {
      state.devicesPagination = { skip: 0, limit: 20 };
    },
    clearFirmwarePagination: (state, action) => {
      state.firmwarePagination = { skip: 0, limit: 20 };
    },
  },
});

export const {
  setAssemblyPagination,
  setDevicePagination,
  setFirmwarePagination,
  clearAssemblyPagination,
  clearDevicesPagination,
  clearFirmwarePagination,
} = paginationSlice.actions;
export default paginationSlice.reducer;

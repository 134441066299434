import axios from "axios";
import api_constants from "apis/api_constants";

export const automaticService = {
  get_device,
  get_devices,
  get_assembly,
  get_assemblies,
  get_firmware_updates,
  getSecondSetOfLabelingPdf,
};

async function get_device(deviceSerialNumber) {
  const requestOptions = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    },
    data: JSON.stringify({
      deviceSerialNumber: deviceSerialNumber,
    }),
  };

  return await axios(api_constants.getDevice, requestOptions)
    .then(handleResponse, (error) => Promise.reject(error))
    .then((data) => {
      return data.device;
    });
}

async function get_devices(
  assemblyStatus,
  calibrationStatus,
  startDate,
  endDate,
  skip,
  limit,
  serialNumber,
  deviceType
) {
  let manufacturerId = JSON.parse(localStorage.getItem("user")).user._id;
  const requestOptions = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    },
    data: JSON.stringify({
      manufacturerId: manufacturerId,
      skip: skip,
      limit: limit,
      assemblyStatus: assemblyStatus,
      calibrationStatus: calibrationStatus,
      startDate: startDate,
      endDate: endDate,
      serialNumber: serialNumber,
      deviceType: deviceType,
    }),
  };

  return await axios(api_constants.getDevices, requestOptions)
    .then(handleResponse, (error) => Promise.reject(error))
    .then((data) => {
      return data.devices;
    });
}

async function get_assembly(pcbSerialNumber) {
  let manufacturerId = JSON.parse(localStorage.getItem("user")).user._id;
  const requestOptions = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    },
    data: JSON.stringify({
      manufacturerId: manufacturerId,
      pcbSerialNumber: pcbSerialNumber,
    }),
  };

  return await axios(api_constants.getAssembly, requestOptions)
    .then(handleResponse, (error) => Promise.reject(error))
    .then((data) => {
      return data.assembly;
    });
}

async function get_assemblies(
  assemblyStatus,
  oadUpdateStatus,
  startDate,
  endDate,
  skip,
  limit,
  serialNumber,
  pcbType
) {
  let manufacturerId = JSON.parse(localStorage.getItem("user")).user._id;
  const requestOptions = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    },
    data: JSON.stringify({
      manufacturerId: manufacturerId,
      skip: skip,
      limit: limit,
      assemblyStatus: assemblyStatus,
      oadUpdateStatus: oadUpdateStatus,
      startDate: startDate,
      endDate: endDate,
      pcbSerialNumber: serialNumber,
      pcbType: pcbType,
    }),
  };

  return await axios(api_constants.getAssemblies, requestOptions)
    .then(handleResponse, (error) => Promise.reject(error))
    .then((data) => {
      return data.assemblies;
    });
}

async function get_firmware_updates(skip, limit) {
  const requestOptions = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    },
    data: JSON.stringify({
      skip: skip,
      limit: limit,
    }),
  };

  return await axios(api_constants.getFirmwareUpdates, requestOptions)
    .then(handleResponse, (error) => Promise.reject(error))
    .then((data) => {
      return data.updates;
    });
}

async function getSecondSetOfLabelingPdf (deviceSerialNumber) {
  const requestUrl = `${api_constants.getSecondSetOfLabelingPdf}/${deviceSerialNumber}.pdf`;
  const requestOptions = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(localStorage.getItem("user")).token}`
    },
    responseType: "arraybuffer",
    responseEncoding: "binary",
  };

  return await axios(requestUrl, requestOptions)
    .then(handleSecondSetOfLabelingResponse, (error) => Promise.reject(error))
    .then((data) => {
      return Buffer.from(data).toString("base64");
    })
}

function handleResponse(response) {
  const data = response.data;
  if (!data.result.success) {
    //window.location.reload(true);
    const error = data.result;
    return Promise.reject(error);
  }
  return data;
}

function handleSecondSetOfLabelingResponse(response) {
  const data = response.data;
  if (!data) {
    //window.location.reload(true);
    const error = data.result;
    return Promise.reject(error);
  }
  return data;
}

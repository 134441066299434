import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { automaticService } from "services";
import { logout } from "../logout";

export const getDevice = createAsyncThunk(
  "getDevice",
  async ({ serialNumber }, { rejectWithValue }) => {
    try {
      const response = await automaticService.get_device(serialNumber);

      return response;
    } catch (error) {
      if (error.message) {
        if (error?.response?.status === 401) {
          logout();
        }
        //No response to request (No service)
        console.log("Internet Disconnected");
      } else {
        console.log(error);
        return rejectWithValue(error);
      }
    }
  }
);

const initialState = {
  result: null,
  loading: false,
  error: null,
};

const deviceSlice = createSlice({
  name: "device",
  initialState: initialState,
  reducers: {
    clearDevice: (state, action) => {
      state.result = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDevice.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getDevice.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action.payload;
      state.error = null;
    });
    builder.addCase(getDevice.rejected, (state, action) => {
      state.loading = false;
      state.result = null;
      state.error = action.payload;
    });
  },
});

export const { clearDevice } = deviceSlice.actions;
export default deviceSlice.reducer;

/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Drawer,
  List,
  ListSubheader,
  Hidden,
  Divider,
  Button,
  ButtonGroup,
  Typography,
  FormControl,
  Select,
  TextField,
  IconButton,
} from "@material-ui/core";
import MoreIcon from "@material-ui/icons/MoreVert";

import { clearDevicesList } from "slices/devicesSlice";
import { clearAssembliesList } from "slices/assembliesSlice";
import { setDeviceFilters, setAssemblyFilters } from "slices/filtersSlice";
import { setCurrentType } from "slices/currentTypeSlice";
import {
  setAssemblyPagination,
  setDevicePagination,
} from "slices/paginationSlice";

import { useLocation } from "react-router-dom";

import { history } from "helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  navigation: {
    overflow: "auto",
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1,
  },
  type: {
    overflow: "hidden",
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1,
  },
  profile: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  details: {
    marginLeft: theme.spacing(2),
  },
  moreButton: {
    marginLeft: "auto",
  },
  groupButtons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 70,
  },
  div: {
    width: "100%",
    display: "flex",
  },
  divChild: {
    flex: "1",
    margin: "5px",
  },
  textField: {
    marginTop: theme.spacing(3),
    width: 150,
  },
}));

//TODO devices and assemblies filter must be moved to different components
function NavBar({ openMobile, onMobileClose, className, ...rest }) {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const [btnStyleAssembly, setBtnStyleAssembly] = useState(0);
  const [btnStyleCalibration, setBtnStyleCalibration] = useState(0);
  const [btnStyleType, setBtnStyleType] = useState(0);
  const [btnStyleOad, setBtnStyleOad] = useState(0);

  const [deviceFilterState, setDeviceFilterState] = useState({
    assemblyStatus: null,
    calibrationStatus: null,
    startDate: null,
    endDate: null,
    deviceType: null,
  });
  const [assemblyFilterState, setAssemblyFilterState] = useState({
    assemblyStatus: null,
    oadUpdateStatus: null,
    startDate: null,
    endDate: null,
    pcbType: null,
  });

  const deviceFilters = rest.filtersState.deviceFilters;
  const assemblyFilters = rest.filtersState.assemblyFilters;
  const currentType = rest.currentType;


  const handleChangePcbType = (event) => {
    let pcbType = null;
      if (event.target.value !== "ALL") {
        pcbType = event.target.value;
      }
      setAssemblyFilterState({
        ...assemblyFilterState,
        pcbType: pcbType,
      });
  };

  const handleChangeDeviceType = (event) => {
    let deviceType = null;
      if (event.target.value !== "ALL") {
        deviceType = event.target.value;
      } 

      setDeviceFilterState({
        ...deviceFilterState,
        deviceType: deviceType,
      });
  };

  const handleChange = (event) => {
    event.persist();

    setDeviceFilterState((prevFilteredState) => ({
      ...prevFilteredState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChangeAssemblies = (event) => {
    event.persist();

    setAssemblyFilterState((prevFilteredState) => ({
      ...prevFilteredState,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    // eslint-disable-next-line
  }, []); //??

  useEffect(() => {
    dispatch(clearDevicesList());
    dispatch(
      setDeviceFilters({
        ...deviceFilters,
        assemblyStatus: deviceFilterState.assemblyStatus,
        calibrationStatus: deviceFilterState.calibrationStatus,
        startDate: deviceFilterState.startDate,
        endDate: deviceFilterState.endDate,
        deviceType: deviceFilterState.deviceType,
      })
    );
    dispatch(
      setDevicePagination({
        skip: 0,
        limit: 20,
      })
    );
  }, [
    dispatch,
    setDeviceFilters,
    clearDevicesList,
    setDevicePagination,
    deviceFilterState,
  ]);

  useEffect(() => {
    dispatch(clearAssembliesList());
    dispatch(
      setAssemblyFilters({
        ...assemblyFilters,
        assemblyStatus: assemblyFilterState.assemblyStatus,
        oadUpdateStatus: assemblyFilterState.oadUpdateStatus,
        startDate: assemblyFilterState.startDate,
        endDate: assemblyFilterState.endDate,
        pcbType: assemblyFilterState.pcbType,
      })
    );
    dispatch(
      setAssemblyPagination({
        skip: 0,
        limit: 20,
      })
    );
  }, [
    dispatch,
    clearAssembliesList,
    setAssemblyFilters,
    setAssemblyPagination,
    assemblyFilterState,
  ]);

  useEffect(() => {
    if (location.pathname.includes("assemblies")) {
      dispatch(setCurrentType("ASSEMBLIES"));
      setBtnStyleType(1);
    } else {
      dispatch(setCurrentType("DEVICES"));
      setBtnStyleType(0);
    }
  }, [dispatch, location.pathname, setCurrentType]);

  const handleChangeAssembly = (result) => {
    if (result === "ALL") {
      result = null;
      setBtnStyleAssembly(0);
    } else if (result === "SUCCESS") {
      result = 1;
      setBtnStyleAssembly(1);
    } else {
      result = 0;
      setBtnStyleAssembly(2);
    }

    setDeviceFilterState({
      ...deviceFilterState,
      assemblyStatus: result,
    });
  };
  const handleChangeCalibration = (result) => {
    if (result === "ALL") {
      result = null;
      setBtnStyleCalibration(0);
    } else if (result === "SUCCESS") {
      result = 1;
      setBtnStyleCalibration(1);
    } else {
      setBtnStyleCalibration(2);
      result = 0;
    }
    setDeviceFilterState({
      ...deviceFilterState,
      calibrationStatus: result,
    });
  };

  const handleChangeAssemblys = (result) => {
    if (result === "ALL") {
      result = null;
      setBtnStyleAssembly(0);
    } else if (result === "SUCCESS") {
      result = 1;
      setBtnStyleAssembly(1);
    } else {
      result = 0;
      setBtnStyleAssembly(2);
    }

    setAssemblyFilterState({
      ...assemblyFilterState,
      assemblyStatus: result,
    });
  };

  const handleChangeType = (type) => {
    if (type === "DEVICES") {
      setBtnStyleType(0);
      history.push("/main/devices/Information");
      window.location.reload(false);
    } else {
      setBtnStyleType(1);
      history.push("/main/assemblies/Information");
      window.location.reload(false);
    }
    dispatch(setCurrentType(type));

    setAssemblyFilterState({
      ...assemblyFilterState,
      assemblyStatus: null,
      oadUpdateStatus: null,
      startDate: null,
      endDate: null,
    });

    setDeviceFilterState({
      ...deviceFilterState,
      assemblyStatus: null,
      calibrationStatus: null,
      startDate: null,
      endDate: null,
    });

    setBtnStyleAssembly(0);
  };

  const handleChangeOADStatus = (result) => {
    if (result === "ALL") {
      result = null;
      setBtnStyleOad(0);
    } else if (result === "TRUE") {
      result = 1;
      setBtnStyleOad(1);
    } else {
      result = 0;
      setBtnStyleOad(2);
    }

    setAssemblyFilterState({
      ...assemblyFilterState,
      oadUpdateStatus: result,
    });
  };

  const content = (
    <div className={clsx(classes.root, className)}>
      <nav className={classes.navigation}>
        <List>
          {JSON.parse(localStorage.getItem("user")) ? (
            <Typography variant="h6" gutterBottom style={{ margin: "10px 0" }}>
              Filters
              <br />
            </Typography>
          ) : null}
          <ListSubheader disableSticky>
            {currentType === "DEVICES" ? (
              <div className="groupButtons">
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ margin: "10px 0" }}
                >
                  Assembly Status
                  <br />
                </Typography>
                <ButtonGroup
                  size="small"
                  aria-label="outlined small button group"
                >
                  <Button
                    children="ALL"
                    color={btnStyleAssembly === 0 ? "primary" : ""}
                    onClick={(event) => {
                      handleChangeAssembly(
                        event.currentTarget.children[0].innerHTML
                      );
                    }}
                  />
                  <Button
                    children="SUCCESS"
                    color={btnStyleAssembly === 1 ? "primary" : ""}
                    onClick={(event) => {
                      handleChangeAssembly(
                        event.currentTarget.children[0].innerHTML
                      );
                    }}
                  />
                  <Button
                    children="FAILURE"
                    color={btnStyleAssembly === 2 ? "primary" : ""}
                    onClick={(event) => {
                      handleChangeAssembly(
                        event.currentTarget.children[0].innerHTML
                      );
                    }}
                  />
                </ButtonGroup>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ margin: "10px 0" }}
                >
                  Calibration Status
                  <br />
                </Typography>
                <ButtonGroup
                  size="small"
                  aria-label="outlined small button group"
                >
                  <Button
                    children="ALL"
                    color={btnStyleCalibration === 0 ? "primary" : ""}
                    onClick={(event) => {
                      handleChangeCalibration(
                        event.currentTarget.children[0].innerHTML
                      );
                    }}
                  />
                  <Button
                    children="SUCCESS"
                    color={btnStyleCalibration === 1 ? "primary" : ""}
                    onClick={(event) => {
                      handleChangeCalibration(
                        event.currentTarget.children[0].innerHTML
                      );
                    }}
                  />
                  <Button
                    children="FAILURE"
                    color={btnStyleCalibration === 2 ? "primary" : ""}
                    onClick={(event) => {
                      handleChangeCalibration(
                        event.currentTarget.children[0].innerHTML
                      );
                    }}
                  />
                </ButtonGroup>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ margin: "10px 0" }}
                >
                  Date (Start - End)
                  <br />
                </Typography>

                <TextField
                  id="date"
                  label="Start"
                  variant="outlined"
                  type="date"
                  name="startDate"
                  onChange={handleChange}
                  defaultValue="2018-05-05"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="date"
                  label="End"
                  name="endDate"
                  variant="outlined"
                  type="date"
                  onChange={handleChange}
                  defaultValue={`${rest.today}`}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Typography variant="h6" gutterBottom style={{ margin: "10px 0" }}>
                  {"Device Type"}
                  <br />
                </Typography>
                <FormControl variant="outlined">
                  <Select
                    native
                    value={deviceFilterState.deviceType}
                    onChange={handleChangeDeviceType}
                    inputProps={{
                      name: "deviceType",
                    }}
                  >
                    <option value="ALL">ALL</option>
                    <option value="SPIROHOME">SPIROHOME PERSONAL</option>
                    <option value="SPIROCLINIC">SPIROHOME CLINIC</option>
                    <option value="SPIROCLINIC_COMPACT">SPIROCLINIC COMPACT</option>
                    <option value="SPIROCLINIC_PRO_HANDPIECE">SPIROCLINIC PRO HANDPIECE</option>
                    <option value="SPIROCLINIC_PRO_DOCK">SPIROCLINIC PRO DOCK</option>
                    <option value="ISPIRO">ISPIRO</option>
                  </Select>
                </FormControl>
              </div>
            ) : (
              <div className="groupButtons">
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ margin: "10px 0" }}
                >
                  OAD Update Status
                  <br />
                </Typography>
                <ButtonGroup
                  size="small"
                  aria-label="outlined small button group"
                >
                  <Button
                    children="ALL"
                    color={btnStyleOad === 0 ? "primary" : ""}
                    onClick={(event) => {
                      handleChangeOADStatus(
                        event.currentTarget.children[0].innerHTML
                      );
                    }}
                  />
                  <Button
                    children="TRUE"
                    color={btnStyleOad === 1 ? "primary" : ""}
                    onClick={(event) => {
                      handleChangeOADStatus(
                        event.currentTarget.children[0].innerHTML
                      );
                    }}
                  />
                  <Button
                    children="FALSE"
                    color={btnStyleOad === 2 ? "primary" : ""}
                    onClick={(event) => {
                      handleChangeOADStatus(
                        event.currentTarget.children[0].innerHTML
                      );
                    }}
                  />
                </ButtonGroup>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ margin: "10px 0" }}
                >
                  Assembly Status
                  <br />
                </Typography>
                <ButtonGroup
                  size="small"
                  aria-label="outlined small button group"
                >
                  <Button
                    children="ALL"
                    color={btnStyleAssembly === 0 ? "primary" : ""}
                    onClick={(event) => {
                      handleChangeAssemblys(
                        event.currentTarget.children[0].innerHTML
                      );
                    }}
                  />
                  <Button
                    children="SUCCESS"
                    color={btnStyleAssembly === 1 ? "primary" : ""}
                    onClick={(event) => {
                      handleChangeAssemblys(
                        event.currentTarget.children[0].innerHTML
                      );
                    }}
                  />
                  <Button
                    children="FAILURE"
                    color={btnStyleAssembly === 2 ? "primary" : ""}
                    onClick={(event) => {
                      handleChangeAssemblys(
                        event.currentTarget.children[0].innerHTML
                      );
                    }}
                  />
                </ButtonGroup>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ margin: "10px 0" }}
                >
                  Date (Start - End)
                  <br />
                </Typography>

                <TextField
                  id="date"
                  label="Start"
                  variant="outlined"
                  type="date"
                  name="startDate"
                  onChange={handleChangeAssemblies}
                  defaultValue="2018-05-05"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="date"
                  label="End"
                  name="endDate"
                  variant="outlined"
                  type="date"
                  onChange={handleChangeAssemblies}
                  defaultValue={`${rest.today}`}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Typography variant="h6" gutterBottom style={{ margin: "10px 0" }}>
                  {"Pcb Type"}
                  <br />
                </Typography>
                <FormControl variant="outlined">
                  <Select
                    native
                    value={assemblyFilterState.pcbType}
                    onChange={handleChangePcbType}
                    inputProps={{
                      name: "pcbType",
                    }}
                  >
                    <option value="ALL">ALL</option>
                    <option value="SPIROHOME">SPIROHOME (CLINIC) AND SPIROCLINIC COMPACT</option>
                    <option value="SPIROCLINIC_PRO_HANDPIECE">SPIROCLINIC PRO HANDPIECE</option>
                    <option value="SPIROCLINIC_PRO_DOCK">SPIROCLINIC PRO DOCK</option>
                    <option value="ISPIRO">ISPIRO</option>
                  </Select>
                </FormControl>
              </div>
            )}
            
          </ListSubheader>
        </List>
      </nav>

      <Divider className={classes.divider} />
      <div className={classes.profile}>
        <div className={classes.details}>
          {JSON.parse(localStorage.getItem("user")) ? (
            <div>
              <Typography variant="h5" color="textSecondary">
                {JSON.parse(localStorage.getItem("user")).user.name +
                  " " +
                  JSON.parse(localStorage.getItem("user")).user.surname}
              </Typography>
              <Typography variant="body2">
                {JSON.parse(localStorage.getItem("user")).user.type}
              </Typography>
            </div>
          ) : null}
        </div>
        <IconButton className={classes.moreButton} size="small" disabled>
          <MoreIcon />
        </IconButton>
      </div>
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer,
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <nav className={classes.type}>
            <List>
              {JSON.parse(localStorage.getItem("user")) ? (
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ margin: "10px 0" }}
                >
                  Filters
                  <br />
                </Typography>
              ) : null}
              <ListSubheader disableSticky>
                <div className="groupButtons">
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ margin: "10px 0" }}
                  >
                    Types
                    <br />
                  </Typography>
                  <ButtonGroup
                    size="small"
                    aria-label="outlined small button group"
                  >
                    <Button
                      children="DEVICES"
                      color={btnStyleType === 0 ? "primary" : ""}
                      onClick={(event) => {
                        handleChangeType(
                          event.currentTarget.children[0].innerHTML
                        );
                      }}
                    />
                    <Button
                      children="ASSEMBLIES"
                      color={btnStyleType === 1 ? "primary" : ""}
                      onClick={(event) => {
                        handleChangeType(
                          event.currentTarget.children[0].innerHTML
                        );
                      }}
                    />
                  </ButtonGroup>
                </div>
              </ListSubheader>
            </List>
          </nav>
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.desktopDrawer,
          }}
          open
          variant="persistent"
        >
          <nav className={classes.type}>
            <List>
              {JSON.parse(localStorage.getItem("user")) ? (
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ margin: "10px 0" }}
                >
                  View
                  <br />
                </Typography>
              ) : null}
              <ListSubheader disableSticky>
                <div className="groupButtons">
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ margin: "10px 0" }}
                  >
                    Types
                    <br />
                  </Typography>
                  <ButtonGroup
                    size="small"
                    aria-label="outlined small button group"
                  >
                    <Button
                      children="DEVICES"
                      color={btnStyleType === 0 ? "primary" : ""}
                      onClick={(event) => {
                        handleChangeType(
                          event.currentTarget.children[0].innerHTML
                        );
                      }}
                    />
                    <Button
                      children="ASSEMBLIES"
                      color={btnStyleType === 1 ? "primary" : ""}
                      onClick={(event) => {
                        handleChangeType(
                          event.currentTarget.children[0].innerHTML
                        );
                      }}
                    />
                  </ButtonGroup>
                </div>
              </ListSubheader>
            </List>
          </nav>
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    currentType: state.currentTypeState.currentType,
    paginationState: state.paginationState,
    filtersState: state.filtersState,
  };
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default connect(mapStateToProps, null)(NavBar);

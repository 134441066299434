import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { automaticService } from "services";
import { logout } from "../logout";

export const getDevices = createAsyncThunk(
  "getDevices",
  async (
    {
      assemblyStatus,
      calibrationStatus,
      startDate,
      endDate,
      skip,
      limit,
      serialNumber,
      deviceType,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await automaticService.get_devices(
        assemblyStatus,
        calibrationStatus,
        startDate,
        endDate,
        skip,
        limit,
        serialNumber,
        deviceType
      );

      return response;
    } catch (error) {
      if (error.message) {
        if (error?.response?.status === 401) {
          logout();
        }
        //No response to request (No service)
        console.log("Internet Disconnected");
      } else {
        console.log(error);
        return rejectWithValue(error);
      }
    }
  }
);

const initialState = {
  result: null,
  loading: false,
  error: null,
  currentDevice: null,
};

const devicesSlice = createSlice({
  name: "devices",
  initialState: initialState,
  reducers: {
    setCurrentDevice: (state, action) => {
      state.currentDevice = action.payload;
    },
    clearDevicesList: (state, action) => {
      state.result = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDevices.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getDevices.fulfilled, (state, action) => {
      state.loading = false;
      state.result = state.result
        ? state.result.concat(action.payload)
        : action.payload;
      state.error = null;
    });
    builder.addCase(getDevices.rejected, (state, action) => {
      state.loading = false;
      state.result = null;
      state.error = action.payload;
    });
  },
});

export const { setCurrentDevice, clearDevicesList } = devicesSlice.actions;
export default devicesSlice.reducer;

import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { history } from "../../helpers";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Button, IconButton, Toolbar, Hidden } from "@material-ui/core";
import InputIcon from "@material-ui/icons/Input";
import MenuIcon from "@material-ui/icons/Menu";
import PeopleIcon from "@material-ui/icons/People";

import { logout } from "../../logout";
import { useDispatch, connect } from "react-redux";
import Popup from "components/Popup";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  topBarButtons: {
    marginLeft: theme.spacing(1),
    color: "white",
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
    color: "inherit",
  },
  header: {
    textDecoration: "none",
    color: "white",
    fontSize: "26px",
    letterSpacing: "1px",
  },
}));

function TopBar({ onOpenNavBarMobile, onOpenPatientListMobile, ...rest }) {
  const classes = useStyles();
  const currentType = rest.currentType;
  const [open, setOpen] = useState(false);
  const [type, setType] = useState();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleClickOpen = (value) => {
    setOpen(true);
    setType(value);
  };

  const handleRouteRedirect = () => {
    if (currentType === "DEVICES") {
      return "/main/devices/Information";
    } else {
      return "/main/assemblies/Information";
    }
  };

  return (
    <AppBar className={classes.root} color="primary">
      <Toolbar>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden xsDown>
          <RouterLink to={handleRouteRedirect}>
            <h1 className={classes.header}>Manufacturing Tool Dashboard</h1>
          </RouterLink>
        </Hidden>
        <div className={classes.flexGrow} />
        <Hidden lgUp>
          {history.location.pathname.includes("subjects") ? (
            <IconButton color="inherit" onClick={onOpenPatientListMobile}>
              <PeopleIcon />
            </IconButton>
          ) : null}
        </Hidden>

        <Button
          className={classes.topBarButtons}
          onClick={() => handleClickOpen(1)}
        >
          Firmware Update List
        </Button>

        <Popup type={type} open={open} setOpen={setOpen} />
        <Button className={classes.topBarButtons} onClick={handleLogout}>
          <InputIcon className={classes.logoutIcon} />
          Sign out
        </Button>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    currentType: state.currentTypeState.currentType,
  };
};

export default connect(mapStateToProps, null)(TopBar);
